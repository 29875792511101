import React, { createRef, useEffect, useRef, useState } from "react";
import { Label } from "@progress/kendo-react-labels";
import {
  AppBar,
  AppBarSection,
  AppBarSpacer,
  TabStrip,
  TabStripTab,
} from "@progress/kendo-react-layout";
import "../src/MyProfile.scss";
import "../src/Common.scss";
import { Popup } from "@progress/kendo-react-popup";
import { Button } from "@progress/kendo-react-buttons";
import { Input, TextArea } from "@progress/kendo-react-inputs";
import { Link, useNavigate } from "react-router-dom";
import {
  ExternalDropZone,
  Upload,
  UploadFileInfo,
  UploadFileStatus,
} from "@progress/kendo-react-upload";
import {
  DropDownList,
  DropDownListChangeEvent,
} from "@progress/kendo-react-dropdowns";
import axios from "axios";
import { Dialog } from "@progress/kendo-react-dialogs";
import {
  commonBaseUrl,
  commonImageUrl,
  decryptBase64Password,
  encryptedBase64Password,
} from "./services/CommonUtils";
import { SvgIcon } from "@progress/kendo-react-common";
import {
  eyeIcon,
  eyeSlashIcon,
  fileImageIcon,
  filePdfIcon,
} from "@progress/kendo-svg-icons";
import { CustomFileList } from "./CustomFileList";
import { Loader } from "@progress/kendo-react-indicators";
import { v4 as uuidv4 } from "uuid";

interface RegisterFormErrors {
  companyNameErr?: string;
  customerNameErr?: string;
  customerMobileErrr?: string;
  customerEmailErrr?: string;
  customerAddres1Eerrr?: string;
  customerAddres2Eerrr?: string;
  customerKYCDocumentErrr?: string;
  customerKYCFileErrr?: string;
  customerPasswordErrr?: string;
  customerConfirmPasswordErrr?: string;
  customerPincodeErrr?: string;
  customerCountryErrr?: string;
  customerStateErrr?: string;
  customerCityErrr?: string;
}

interface ErrorRegisterRequired {
  companyNameErr?: boolean;
  customerNameErr?: boolean;
  customerMobileErrr?: boolean;
  customerEmailErrr?: boolean;
  customerAddres1Eerrr?: boolean;
  customerAddres2Eerrr?: boolean;
  customerKYCDocumentErrr?: boolean;
  customerPassowordErrr?: boolean;
  customerConfirmPasswordErrr?: boolean;
  customerPincodeErrr?: boolean;
  customerCountryErrr?: boolean;
  customerStateErrr?: boolean;
  customerCityErrr?: boolean;
}

interface PasswordFormErrors {
  customerCurrentPasswordErrr?: string;
  customerPasswordErrr?: string;
  customerConfirmPasswordErrr?: string;
}

interface ErrorPasswordRequired {
  customerCurrentPassowordErrr?: boolean;
  customerPassowordErrr?: boolean;
  customerConfirmPasswordErrr?: boolean;
}

interface KYCFormErrors {
  customerKYCDocumentErrr?: string;
  customerKYCFileErrr?: string;
  customerKycNumberErrr?: string;
}

interface ErrorKYCRequired {
  customerKYCDocumentErrr?: boolean;
  customerKycNumberErrr?: boolean;
}

export const MyProfile = () => {
  const baseURL = commonBaseUrl();
  const imageURL = commonImageUrl();

  const [showProfileMessage, setShowProfileMessage] = useState(false);
  const [showMessage, setShowMessage] = useState(true);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [visible, setVisible] = React.useState<boolean>(false);
  const [changeDialog, setChangeDialog] = React.useState<boolean>(false);
  const [alertContent, setAlertContent] = React.useState<String>("");
  const [isEdit, setIsEdit] = useState(true);
  const [isFileCheck, setIsFileCheck] = useState(false);
  const [loggedIn, setLoggedIn] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const anchorRef = useRef(null);
  const popupRef = useRef(null);
  const defaultKYCDicumentValue = { Name: "Select KYC Document" };
  const [selectedKYC, setSelectedKYC] = useState(defaultKYCDicumentValue);
  const [selectedKYCID, setSelectedKYCID] = useState(0);
  const [selectedKYCName, setSelectedKYCName] = useState<string>();
  const hasFetched = useRef(false);
  const [selectedTab, setSelectedTab] = useState(0);
  const [imageData, setImageData] = useState("");
  const [profileImageData, setProfileImageData] = useState("");
  const [currentFile, setCurrentFile] = useState<UploadFileInfo>();
  const [currentProfileFile, setCurrentProfileFile] =
    useState<UploadFileInfo>();
  const [logoutDialog, setLogoutDialog] = useState(false); // State to control the popup
  const [isCheckRegisterRequired, setIsCheckRegisterRequired] =
    useState<ErrorRegisterRequired>();
  const [changePasswordError, setChangePasswordError] =
    useState<PasswordFormErrors>();
  const [isChangePasswordError, setIsChangePasswordError] =
    useState<ErrorPasswordRequired>();
  const [changeKYCError, setChangeKYCError] = useState<KYCFormErrors>();
  const [isChangeKYCError, setIsChangeKYCError] = useState<ErrorKYCRequired>();
  // const [profileNote, setProfileNote] = useState();

  const profilebase64Image =
    "iVBORw0KGgoAAAANSUhEUgAAAOEAAADhCAMAAAAJbSJIAAAAPFBMVEXk5ueutLepsLPo6uursbXJzc/p6+zj5ea2u76orrKvtbi0ubzZ3N3O0dPAxcfg4uPMz9HU19i8wcPDx8qKXtGiAAAFTElEQVR4nO2d3XqzIAyAhUD916L3f6+f1m7tVvtNINFg8x5tZ32fQAIoMcsEQRAEQRAEQRAEQRAEQRAEQRAEQRAEQRAEQRAEQTghAJD1jWtnXJPP/54IgNzZQulSmxvTH6oYXX4WS+ivhTbqBa1r26cvCdCu6i0YXbdZ0o4A1rzV+5IcE3YE+z58T45lqo7g1Aa/JY5tgoqQF3qb382x7lNzBLcxft+O17QUYfQI4IIeklKsPSN4i6LKj/7Zm8n99RbHJpEw9gEBXNBpKIYLJqKYRwjOikf//r+J8ZsVuacbqCMNleI9TqGLGqMzhnVdBOdd6F/RlrFijiCoVMk320CBIahUxTWI0KKEcJqKbMdpdJb5QvdHq6wCI5qhKlgGMS/RBHkubWDAE+QZxB4xhCyDiDkLZxgGEVdQldzSKbTIhmZkFkSEPcVvmBn2SMuZB9od7fQDsMiDdKJjFUSCQarM5WirZ3C2TT/htYnyPcPfgrFHWz0BI74gr6J/IZiGUxAZGQLqmvQLTrtE/Go4YxhVRIpEw+sww1IIcqr5NKmUUzLF3d4/qPkYIp2T/obPuemlojFUR4t9Q2Vojhb7BmgElWHzLPH8hucfpefPNFTVgs9h1AdU/Pin96vwWbWdf+X9Absn3OdO34aMdsDnP8WgKYisTqI6CkNGqZQo1XA6Ef6AU32SJzOcBukHPF07/xNSgmHKa5BOhtezv6mA/rYJpwXNAnbRZ1XuF3BzDcO3vpA3+ny2909gbqE4hhD3LIPhLLyBNhPZvbZ3B+3tPYa18A7auSlXQayKwTPNLKDcuOB0xPYKDPFTkWsevQPRZ1J8Hji9I1KQ34r7hZhrwNwOZ97QxNx0drwn4QI0wQk1DcEsfKCWKdxVvxPSNUIp/knmAXT+nT+Ko3+0H96rcNb3m1fx7MBTJdeBJ7uFcWsc0wvgAsC4pROW0l2inbAmIBv/7GZmuhQH6API2rr8T0e6yuZJ+80A9LZeG62T3tik31XwxtwZcizKuTHkMjB1WdZde4Kmic/A5ZI3rr1ae21d08PlVHYfAaxw9G9CYRbJ+8ZdbTcMRV1XM3VdF0M32vtoTdZ0+u29s0OttJ5bz64UwinjaFMVY9vkqc3KKSxN21Xl+0L4Q3Vuv1tYl0pqnX6ms4XetFz7gdZVAgUEoJntfOUe4ZwsHd9FzqQ3Vv6xe41l0XJcqcKl6TZvlv7ClAW3BsqQW4X7ypApB8dmTgK4IX5wvqIVj33HtD2qSG4BqznxdIefL27Y4sahi0MdIdvUsDva8agGGbCtITmCY31MHD2O0uIdh/0rJDQ1VX5Zdxz3rR2QDbv6qXl9vudzqQtGm1Jv9LDXOsfvvB7VcZ8PDKD0mQ1VHPYQ9O+Yj4hR1IUD8rBnn3ho2m8oQMxbCFiKlL2ioSW5heeJqegED52CzxCtcGD3Kv8Wms9EYLyUhwaFIhSMBClevWEmiK/Iaogu4H7sg6ppQhQG8RUqivuTGOAJOg6FfgW0q0M0PQMRMEgXaeNf3SYDZ8PIMI0+wHgr/MgN7wYwpiLjCCqM6ydUDZLQiB6nDdNC8SDyig3jPPpFXGcC9O8BUBDVmgBY59E7Md/35Loe/UVEECEJwYggJjELZ4J71SaQSBeC02n4Da29CayJNA28SAhd2CQyC1Xw6pSmGSINQVuMhAZp4DClan9MgmkDDNmezqwS8sgtlXK/EPBhoaSmYVC/F7IO1jQEdHOlabpKh3+jzLQSTUiq4X2I+Ip/zU8rlaqAvkS21ElR+gqu3zbjjL+hIAiCIAiCIAiCIAiCsCf/AKrfVhSbvA+DAAAAAElFTkSuQmCC";
  const profileName = "A6CC0B0F4DP2012E.png";
  const profileType = "png";
  const profileUID = uuidv4();

  const storeUserDetails = {
    companyName: localStorage.getItem("company_name"),
    firstName: localStorage.getItem("name"),
    lastName: localStorage.getItem("last_name"),
    mobileNumber: localStorage.getItem("mobile"),
    emailAddress: localStorage.getItem("email"),
    addressLine1: localStorage.getItem("address"),
    addressLine2: localStorage.getItem("address2"),
    customerID: localStorage.getItem("customerId"),
    country: localStorage.getItem("country"),
    state: localStorage.getItem("state"),
    city: localStorage.getItem("city"),
    pincode: localStorage.getItem("pin_code"),
    gstNumber: localStorage.getItem("gst_number"),
    kycDocumentNumber: localStorage.getItem("kycDocumentNumber"),
    profileImage: null as File | null,
  };

  const [selectedValue, setSelectedValue] = useState(
    localStorage.getItem("customer_type") === "1" ? "Company" : "Individual"
  );

  const [formData, setFormData] = useState(storeUserDetails);
  const [registerRoomBookErrorFormData, setRegisterRoomBookErrorFormData] =
    useState<RegisterFormErrors>();

  // validate password
  const MIN_LENGTH = 8;
  const hasUppercase = /[A-Z]/;
  const hasLowercase = /[a-z]/;
  const hasNumber = /\d/;
  const hasSymbol = /[!@#$%^&*_]/;
  const hasSpace = /\s/;

  // Country , State & City API flow
  const defaultCountryValue = { countryName: "Select Country" };
  const defaultStateValue = { stateName: "Select State" };
  const defaultCityValue = { cityName: "Select City" };

  const [countryData, setCountryData] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState(defaultCountryValue);
  const [selectedCountryId, setSelectedCountryId] = useState(0);

  const [stateData, setStateData] = useState([]);
  const [selectedState, setSelectedState] = useState(defaultStateValue);
  const [selectedStateId, setSelectedStateId] = useState(0);

  const [cityData, setCityData] = useState([]);
  const [selectedCity, setSelectedCity] = useState(defaultCityValue);
  const [selectedCityId, setSelectedCityId] = useState(0);

  const [countryName, setCountryName] = useState("");
  const [stateName, setStateName] = useState("");
  const [cityName, setCityName] = useState("");
  const [profileCheck, setProfileCheck] = useState(0);

  // Validate password
  const validatePassword = (value: string) => {
    if (hasSpace.test(value)) {
      return "Password should not contain spaces.";
    }
    if (value.length < MIN_LENGTH) {
      return `Password must be at least ${MIN_LENGTH} characters long.`;
    }
    if (!hasUppercase.test(value)) {
      return "Password must contain at least one uppercase letter.";
    }
    if (!hasLowercase.test(value)) {
      return "Password must contain at least one lowercase letter.";
    }
    if (!hasSymbol.test(value)) {
      return "Password must contain at least one special character.";
    }
    if (!hasNumber.test(value)) {
      return "Password must contain at least one number.";
    }

    return ""; // Password is valid
  };

  // Convert Date Server to MMM DD YYYY
  const convertDate = (dateString: any) => {
    // Create a new Date object from the date string
    const date = new Date(dateString);

    // Define month names to convert the numeric month to abbreviated form
    const months = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];

    // Extract the parts of the date you need
    const year = date.getFullYear();
    const month = months[date.getMonth()]; // Get the month as a string
    const day = date.getDate();

    // Format it in the desired format "Sep 27 2024"
    return `${day} ${month} ${year}`;
  };

  const handleTabSelect = (e: any) => {
    setSelectedTab(e.selected);
    if (e.selected === 0) {
      handlePasswordReset();
      getKYCFile();
    } else if (e.selected === 1) {
      getKYCFile();
      handleDisableWithReset();
    } else if (e.selected === 2) {
      handlePasswordReset();
      handleDisableWithReset();
    }
  };

  const uploadRefs = createRef<Upload>();
  const hint = (
    <span>Click to select files or Drag and drop files here to upload.</span>
  );
  const note = (
    <span>
      Only JPEG, JPG, PNG and PDF files are allowed. The maximum upload size is
      2 MB
    </span>
  );

  // Save file upload local
  function onSaveRequest(
    files: UploadFileInfo[],
    options: { formData: FormData; requestOptions: any },
    onProgress: (uid: string, event: ProgressEvent<EventTarget>) => void
  ): Promise<{ uid: string }> {
    const currentFile = files[0] as UploadFileInfo;
    setCurrentFile(currentFile);
    const uid = currentFile.uid;
    return new Promise<{ uid: string }>((resolve, reject) => {
      if (
        currentFile.validationErrors &&
        currentFile.validationErrors.length > 0
      ) {
        reject({ uid: uid });
      } else {
        const reader = new FileReader();
        reader.onload = () => {
          if (reader.result && typeof reader.result === "string") {
            const base64Result = reader.result.split(",")[1];
            setImageData(base64Result);
            resolve({ uid: uid });
          } else {
            reject({ uid: uid });
          }
        };
        reader.onprogress = (data) => {
          onProgress(uid, data);
        };
        reader.onabort = () => {
          reject({ uid: uid });
        };
        reader.onerror = () => {
          reject({ uid: uid });
        };

        reader.readAsDataURL(currentFile.getRawFile!());
      }
    });
  }

  const [files, setFiles] = React.useState<any[]>([]);
  const [profileImage, setProfileImage] = useState<string | null>(null); // Store the base64 image temporarily
  const [showFileList, setShowFileList] = useState(true); // State to manage file list visibility

  function onProfileSaveRequest(
    files: UploadFileInfo[],
    options: { formData: FormData; requestOptions: any },
    onProgress: (uid: string, event: ProgressEvent<EventTarget>) => void
  ): Promise<{ uid: string }> {
    const currentFile = files[0] as UploadFileInfo;
    setCurrentProfileFile(currentFile);
    const uid = currentFile.uid;
    return new Promise<{ uid: string }>((resolve, reject) => {
      if (
        currentFile.validationErrors &&
        currentFile.validationErrors.length > 0
      ) {
        reject({ uid: uid });
      } else {
        const reader = new FileReader();
        reader.onload = () => {
          if (reader.result && typeof reader.result === "string") {
            const base64Result = reader.result.split(",")[1];
            setProfileImageData(base64Result);
            setProfileImage(reader.result); // Set the base64 image as profile image for display
            setFiles([currentFile]); // Add the selected file to state
            resolve({ uid: uid });
          } else {
            reject({ uid: uid });
          }
        };
        reader.onprogress = (data) => {
          onProgress(uid, data);
        };
        reader.onabort = () => {
          reject({ uid: uid });
        };
        reader.onerror = () => {
          reject({ uid: uid });
        };

        reader.readAsDataURL(currentFile.getRawFile!());
      }
    });
  }

  // Remove function remains the same
  const onRemove = (file: any) => {
    const newFiles = files.filter((f) => f.uid !== file.uid);
    if (newFiles.length === 0) {
      setShowFileList(false); // Hide file list if no files are left
    }
    setFiles((prevFiles) => prevFiles.filter((f) => f.uid !== file.uid));
    setIsFileCheck(false);
    setProfileImage("");
  };

  // Function to convert image URL to a file-like object
  const createFileFromUrl = (
    imageUrl: string,
    fileName: string,
    fileType: any
  ) => {
    const uid = fileName + Math.random().toString(36).substr(2, 9);

    return {
      uid: uid,
      name: fileName,
      progress: 0,
      size: 1024,
      extension: fileType,
      url: imageUrl,
    };
  };

  const [profileData, setprofileData] = React.useState([]);
  const getProfile = async () => {
    try {
      const response = await axios.get(
        `${baseURL}odata/DocumentUploads?$filter=refno eq ${localStorage.getItem(
          "id"
        )} and contains(reftype, 'Profile')`
      );
      if (response.data.value.length !== 0) {
        if (
          response.data.value[response.data.value.length - 1].Name ===
          "A6CC0B0F4DP2012E"
        ) {
          setFiles([]);
          setIsFileCheck(false);
          setProfileImage("");
        } else {
          setprofileData(response.data.value);
          const fileURL: any =
            imageURL +
            response.data.value[response.data.value.length - 1].ImagePath;
          const fileName =
            response.data.value[response.data.value.length - 1].Name +
            "." +
            response.data.value[response.data.value.length - 1].Type;
          setProfileImage(fileURL);
          const newFile = createFileFromUrl(
            fileURL,
            fileName,
            response.data.value[response.data.value.length - 1].Type
          );
          setFiles((prevFiles) => [...prevFiles, newFile]);
          setIsFileCheck(true);
          setFiles([newFile]);
        }
      } else {
        setIsFileCheck(false);
        setProfileImage("");
      }
    } catch (err) {
      alert(err);
    }
  };

  // Remove request remains unchanged
  function onRemoveRequest(
    files: UploadFileInfo[],
    options: { formData: FormData; requestOptions: any }
  ): Promise<{ uid: string }> {
    const currentFile = files[0] as UploadFileInfo;
    const uid = currentFile.uid;
    setImageData("");
    return new Promise<{ uid: string }>((resolve) => {
      resolve({ uid: uid });
    });
  }

  const handlePasswordChanges = (e: any) => {
    const { name, value } = e.target;
    if (name === "current") {
      setCurrentPassword(value);
    } else if (name === "password") {
      setProfilePassword(value);
      const error = validatePassword(value);
      setPasswordValidationError(error);
    } else if (name === "confirmPassword") {
      setConfirmPassword(value);
    }
  };

  const handlePasswordReset = () => {
    setCurrentPassword("");
    setProfilePassword("");
    setConfirmPassword("");
    setChangePasswordError({});
    setIsChangePasswordError({});
  };

  const handleInputChange = (e: any) => {
    const { name, value } = e.target;
    if (name === "gstNumber") {
      const value = e.target.value.toUpperCase();
      setFormData({
        ...formData,
        [name]: value.replace(/[^a-zA-Z0-9]/g, ""),
      });
    } else if (
      name === "companyName" ||
      name === "firstName" ||
      name === "lastName"
    ) {
      setFormData((prevData) => ({
        ...prevData,
        [name]: value.replace(/[^A-Za-z ]/, ""),
      }));
    } else if (name === "kycDocumentNumber") {
      setFormData({
        ...formData,
        [name]: value,
      });
      setkycNumber(e.value);
    } else if (name === "pincode") {
      if (/^\d*$/.test(value)) {
        setFormData({
          ...formData,
          [name]: value,
        });
      }
    } else if (name === "password") {
      setProfilePassword(value);
    } else {
      setFormData({
        ...formData,
        [name]: value,
      });
    }
  };

  // My profile card
  const AccountCard: React.FC = () => {
    const companyName =
      localStorage.getItem("company_name") === ""
        ? localStorage.getItem("name")
        : localStorage.getItem("company_name");
    const accountType =
      localStorage.getItem("customer_type") === "1" ? "Company" : "Individual";
    const userId = localStorage.getItem("id");
    const joinedDate = convertDate(localStorage.getItem("created"));
    return (
      <div className="account-card">
        <div className="header">
          <h2 className="account-h2">{companyName}</h2>
        </div>
        <div className="details-container">
          <div className="detail">
            <span className="label">Account Type</span>
            <span className="value">{accountType}</span>
          </div>
          <div className="detail">
            <span className="label">User ID</span>
            <span className="value">{userId}</span>
          </div>
          <div className="detail">
            <span className="label">Joined on</span>
            <span className="value">{joinedDate}</span>
          </div>
        </div>
      </div>
    );
  };

  //   Profile UseEffect
  useEffect(() => {
    // Prevent second call
    if (hasFetched.current) return;
    hasFetched.current = true;
    var savedPreference = localStorage.getItem("name");
    if (savedPreference !== "" && savedPreference !== null) {
      setLoggedIn(true);
    }
    getProfile();
    getKYCFile();
    getCountry();
  });

  // Close popup if clicked outside
  useEffect(() => {
    const handleClickOutside = (event: any) => {
      // Check if the click is outside both the popup and the anchor (profile image)
      const path = event.composedPath ? event.composedPath() : event.path;
      if (
        !path.includes(popupRef.current) &&
        !path.includes(anchorRef.current)
      ) {
        setShowPopup(false);
      }
    };

    if (showPopup) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    // Clean up the event listener when component unmounts or showPopup changes
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [showPopup]);

  const getCountry = async () => {
    try {
      const response = await axios.get(`${baseURL}MasterData/GetAllCountries`);
      setCountryData(response.data.content);
      if (isEdit) {
        const defaultCountry = response.data.content.find(
          (current: any) =>
            current.id === parseInt(localStorage.getItem("countryId")!)
        );
        setCountryName(defaultCountry.countryName);
        setSelectedCountry(defaultCountry);
        setSelectedCountryId(defaultCountry.id);
        getState(defaultCountry.id);
      }
    } catch (err) {
      alert(err);
    }
  };

  const getState = async (countryId: any) => {
    try {
      const response = await axios.get(
        `${baseURL}MasterData/GetAllStates?countryId=${countryId}`
      );
      if (response.data.content.length > 0) {
        setStateData(response.data.content);
        if (isEdit) {
          const defaultState = response.data.content.find(
            (current: any) =>
              current.id === parseInt(localStorage.getItem("stateId")!)
          );

          setStateName(defaultState.stateName);
          setSelectedState(defaultState);
          setSelectedStateId(defaultState.id);
          getCity(defaultState.id);
        }
      } else {
        setStateName("");
        setSelectedState(defaultStateValue);
        setSelectedStateId(0);
        setStateData([]);
      }
    } catch (err) {
      alert(err);
    }
  };

  const getCity = async (stateId: any) => {
    try {
      const response = await axios.get(
        `${baseURL}MasterData/GetAllCities?stateId=${stateId}`
      );
      if (response.data.content.length > 0) {
        setCityData(response.data.content);
        if (isEdit) {
          const defaultCity = response.data.content.find(
            (current: any) =>
              current.id === parseInt(localStorage.getItem("cityId")!)
          );

          setCityName(defaultCity.cityName);
          setSelectedCity(defaultCity);
          setSelectedCityId(defaultCity.id);
        }
      } else {
        setCityName("");
        setSelectedCity(defaultCityValue);
        setSelectedCityId(0);
        setCityData([]);
      }
    } catch (err) {
      alert(err);
    }
  };

  // Login status
  const handleLoginLogout = () => {
    setLogoutDialog(true);
    setAlertContent("Are you sure you want to logout?");
    setShowPopup(false); // Close the popup after clicking
  };

  // Popup visibility
  const handleProfileClick = () => {
    setShowPopup(!showPopup);
  };

  // Edit basic details flow
  const handleEditOption = () => {
    setIsEdit(false);
    window.setTimeout(() => {
      window.scroll({
        top: 640,
        left: 0,
        behavior: "smooth",
      });
    }, 5);
    setShowProfileMessage(true);
  };

  const handleDisableWithReset = () => {
    setFiles([]);
    getProfile();
    setIsEdit(true);
    setFormData(storeUserDetails);
    setShowProfileMessage(false);
    setProfileCheck(0);
  };

  let header = {
    "Content-Type":
      "application/json;odata.metadata=minimal;odata.streaming=true",
    accept: "*/*",
  };

  const profileValidation = () => {
    const errors: RegisterFormErrors = {};
    const isCheckErrors: ErrorRegisterRequired = {};
    // Company Name validation
    if (selectedValue === "Company" && !formData.companyName!.trim()) {
      errors.companyNameErr = "Company name is required";
      isCheckErrors.companyNameErr = true;
    }

    // Name validation
    if (!formData.firstName!.trim()) {
      if (selectedValue === "Company") {
        errors.customerNameErr = "Contact person name is required";
        isCheckErrors.customerNameErr = true;
      } else {
        errors.customerNameErr = "Your name is required";
        isCheckErrors.customerNameErr = true;
      }
    }

    // Address validation
    if (!formData.addressLine1!.trim()) {
      if (selectedValue === "Company") {
        errors.customerAddres1Eerrr = "Company address 1 is required";
        isCheckErrors.customerAddres1Eerrr = true;
      } else {
        errors.customerAddres1Eerrr = "Address 2 is required";
        isCheckErrors.customerAddres1Eerrr = true;
      }
    }

    if (!formData.addressLine2!.trim()) {
      if (selectedValue === "Company") {
        errors.customerAddres2Eerrr = "Company address 2 is required";
        isCheckErrors.customerAddres2Eerrr = true;
      } else {
        errors.customerAddres2Eerrr = "Address 2 is required";
        isCheckErrors.customerAddres2Eerrr = true;
      }
    }

    if (selectedCountryId === 0) {
      if (selectedValue === "Company") {
        errors.customerCountryErrr = "Company country is required";
        isCheckErrors.customerCountryErrr = true;
      } else {
        errors.customerCountryErrr = "Country is required";
        isCheckErrors.customerCountryErrr = true;
      }
    }
    if (selectedStateId === 0) {
      if (selectedValue === "Company") {
        errors.customerStateErrr = "Company state is required";
        isCheckErrors.customerStateErrr = true;
      } else {
        errors.customerStateErrr = "State is required";
        isCheckErrors.customerStateErrr = true;
      }
    }
    if (selectedCityId === 0) {
      if (selectedValue === "Company") {
        errors.customerCityErrr = "Company city is required";
        isCheckErrors.customerCityErrr = true;
      } else {
        errors.customerCityErrr = "City is required";
        isCheckErrors.customerCityErrr = true;
      }
    }
    // Pincode validation
    if (!formData.pincode!.trim()) {
      if (selectedValue === "Company") {
        errors.customerPincodeErrr = "Company pincode is required";
        isCheckErrors.customerPincodeErrr = true;
      } else {
        errors.customerPincodeErrr = "Pincode is required";
        isCheckErrors.customerPincodeErrr = true;
      }
    }

    setRegisterRoomBookErrorFormData(errors);
    setIsCheckRegisterRequired(isCheckErrors);
    return Object.keys(errors).length === 0;
  };

  const updateRegisterUserAPI = async (e: any) => {
    e.preventDefault();
    setShowProfileMessage(false);

    const currentDate = new Date();
    const isoString = currentDate.toISOString();

    if (profileCheck !== 2) {
      if (profileValidation()) {
        // setLoading(true);
        // Creating the register data object
        let registerData = {
          Id: Number(localStorage.getItem("id")),
          Name: formData.firstName,
          Password: localStorage.getItem("password"),
          Mobile: Number(formData.mobileNumber),
          Email: formData.emailAddress,
          CompanyId: 1,
          PointOfContact: null,
          KYCDocument: "Yes",
          UserType: true,
          RoleId: 2,
          OTP: 0,
          IsActive: true,
          IsDelete: false,
          CreatedBy: 1,
          CreatedOn: localStorage.getItem("created"),
          ModifiedBy: 1,
          ModifiedOn: isoString,
          Address: formData.addressLine1,
          CompanyName: formData.companyName,
          customerId: localStorage.getItem("customerId"),
          KycDocumentId: Number(localStorage.getItem("kyc_document_id")),
          GstNumber: formData.gstNumber,
          Notes: "",
          CustomerType: Number(localStorage.getItem("customer_type")),
          address2: formData.addressLine2,
          country: countryName !== "" ? countryName : formData.country,
          state: stateName !== "" ? stateName : formData.state,
          city: cityName !== "" ? cityName : formData.city,
          PinCode: formData.pincode,
          LastName: formData.lastName,
          countryId: selectedCountryId,
          stateId: selectedStateId,
          cityId: selectedCityId,
          KycDocumentNumber: localStorage.getItem("kycDocumentNumber"),
        };
        // console.log("formdata", registerData);

        try {
          // API call using axios
          const response = await axios.post(
            `${baseURL}odata/UpdateUser?key=${Number(
              localStorage.getItem("id")
            )}`,
            registerData,
            {
              headers: header,
            }
          );

          // Check if response exists and has status 200
          if (response && response.status === 200) {
            updateuserDetails(response.data);
            uploadProfileFiles(
              localStorage.getItem("id")!,
              currentProfileFile,
              profileImageData
            );
          } else {
            // Show error message or alert
            setVisible(true);
            setAlertContent("Server not reachable. Please try again later!");
            setLoading(false);
          }
        } catch (err: any) {
          // Handling the error in catch block
          if (err.response) {
            // Check if err.response and err.response.status exist
            if (err.response.status === 500) {
              // Show error message or alert
              setVisible(true);
              setAlertContent(`${err.response.data.detail}`);
              setLoading(false);
            }
          } else {
            // Show generic error message or alert
            setVisible(true);
            setAlertContent("An error occurred. Please try again.");
            setLoading(false);
          }
        }
      }
    }
  };

  // Upload file API
  const uploadProfileFiles = async (
    bookingId: String,
    file: any,
    base64Result: string
  ) => {
    let uploadData = [];
    if (profileImage === "") {
      uploadData = [
        {
          docUploadGuid: profileUID,
          docUploadRefType: "Profile",
          docUploadRefNo: Number(bookingId),
          docUploadRefSubType: "Space",
          image: [
            {
              value: profilebase64Image,
              fileName: profileName,
              type: profileType,
            },
          ],
        },
      ];
    } else {
      uploadData = [
        {
          docUploadGuid: file?.uid,
          docUploadRefType: "Profile",
          docUploadRefNo: Number(bookingId),
          docUploadRefSubType: "Space",
          image: [
            {
              value: base64Result,
              fileName: file?.name,
              type: file?.extension?.split(".").pop(),
            },
          ],
        },
      ];
    }
    console.log("uploadData", uploadData);
    try {
      const response = await axios.post(
        `${baseURL}odata/JsonUpload`,
        uploadData,
        {
          headers: header,
        }
      );
      if (response.status === 200) {
        setIsEdit(true);
        setVisible(true);
        setAlertContent("User details has been updated successfully");
        setLoading(false);
      }
    } catch (err) {
      setLoading(false);
    }
  };

  const updateuserDetails = (data: any) => {
    console.log("Customer ID -->", data);
    localStorage.setItem("company_name", data["companyName"]);
    localStorage.setItem("name", data["name"]);
    localStorage.setItem("mobile", data["mobile"]);
    localStorage.setItem("email", data["email"]);
    localStorage.setItem("address", data["address"]);
    localStorage.setItem("address2", data["address2"]);
    localStorage.setItem("country", data["country"]);
    localStorage.setItem("state", data["state"]);
    localStorage.setItem("city", data["city"]);
    localStorage.setItem("countryId", data["countryId"]);
    localStorage.setItem("stateId", data["stateId"]);
    localStorage.setItem("cityId", data["cityId"]);
    localStorage.setItem("last_name", data["lastName"]);
    localStorage.setItem("pin_code", data["pinCode"]);
    localStorage.setItem("gst_number", data["gstNumber"]);
  };
  //

  // Change password flow
  const [profilePassword, setProfilePassword] = React.useState("");
  const [confirmPassword, setConfirmPassword] = React.useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const [currentPassword, setCurrentPassword] = useState("");
  const [showCurrentPassword, setShowCurrentPassword] = useState(false);
  const [passwordValidationError, setPasswordValidationError] = useState("");

  const toggleCurrentPasswordVisibility = () => {
    setShowCurrentPassword(!showCurrentPassword);
  };

  // Toggle between password visibility
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  // Toggle between password visibility
  const toggleConfirmPasswordVisibility = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  const changePasswordValidation = () => {
    const errors: PasswordFormErrors = {};
    const isCheckErrors: ErrorPasswordRequired = {};

    if (currentPassword.trim() === "") {
      console.log("currentPassword");
      errors.customerCurrentPasswordErrr = "Password is required";
      isCheckErrors.customerCurrentPassowordErrr = true;
    } else if (
      currentPassword.trim() !==
      decryptBase64Password(localStorage.getItem("password"))
    ) {
      errors.customerCurrentPasswordErrr =
        "Current password does not match with user password";
      isCheckErrors.customerCurrentPassowordErrr = true;
    }

    if (profilePassword.trim() === "") {
      errors.customerPasswordErrr = "New password is required";
      isCheckErrors.customerPassowordErrr = true;
    } else if (passwordValidationError !== "") {
      errors.customerPasswordErrr =
        "Password must be 8 digits and contain Uppercase, Lowercase, Special Characters, and numbers";
      isCheckErrors.customerPassowordErrr = true;
    }

    if (confirmPassword.trim() === "") {
      errors.customerConfirmPasswordErrr = "Re-enter New Password is required";
      isCheckErrors.customerConfirmPasswordErrr = true;
    } else if (profilePassword !== confirmPassword) {
      errors.customerConfirmPasswordErrr = "Password does not match";
      isCheckErrors.customerConfirmPasswordErrr = true;
    }

    setChangePasswordError(errors);
    setIsChangePasswordError(isCheckErrors);
    return Object.keys(errors).length === 0;
  };

  const changePasswordAPI = async () => {
    if (changePasswordValidation()) {
      const hashPassword = encryptedBase64Password(profilePassword);
      const passwordData = {
        password: hashPassword,
      };
      try {
        const response = await axios.patch(
          `${baseURL}odata/Users/${Number(localStorage.getItem("id"))}`,
          passwordData,
          {
            headers: header,
          }
        );
        console.log(response);
        if (response.status === 204) {
          setChangeDialog(true);
          setAlertContent(
            "Password has been changed successfully. Please re-login to continue"
          );
          setLoading(false);
        } else {
          setVisible(true);
          setAlertContent("Server not rechable. Please try again later!");
          setLoading(false);
        }
      } catch (err: any) {
        if (err.response.status === 500) {
          setVisible(true);
          setAlertContent(`${err.response.data.detail}`);
          setLoading(false);
        }
      }
    }
  };

  const navigateNextScreen = () => {
    navigate("/bookings");
  };

  const logoutHandler = (isLogout: any) => {
    if (isLogout === true) {
      removeUserDetails();
    }
    setLogoutDialog(false);
  };

  const removeUserDetails = () => {
    localStorage.removeItem("id");
    localStorage.removeItem("customer_type");
    localStorage.removeItem("company_name");
    localStorage.removeItem("name");
    localStorage.removeItem("mobile");
    localStorage.removeItem("email");
    localStorage.removeItem("address");
    localStorage.removeItem("customerId");
    localStorage.removeItem("address2");
    localStorage.removeItem("country");
    localStorage.removeItem("state");
    localStorage.removeItem("city");
    localStorage.removeItem("last_name");
    localStorage.removeItem("pin_code");
    localStorage.removeItem("kyc_document_id");
    localStorage.removeItem("gst_number");
    localStorage.removeItem("created");
    localStorage.removeItem("token");
    navigate("/");
  };
  //

  // KYC Document flow
  const [initialDocument, setInitialDocument] = useState<UploadFileInfo[]>();
  const [kycFileName, setKycFileName] = useState("");
  const [kycFilePath, setKycFilePath] = useState("");
  const [kycDetailsData, setkycDetailsData] = useState([]);
  const [kycNumber, setkycNumber] = useState("");
  const fileTypeIcons = {
    pdf: filePdfIcon, // Add appropriate icons or use images here
    jpg: fileImageIcon,
    jpeg: fileImageIcon,
    png: fileImageIcon,
    default: fileImageIcon,
  };
  // Derive file type from file name
  let fileIcon = fileTypeIcons.default; // Default to a folder icon if no match

  const validationMessages = {
    aadhar: "Invalid Aadhar number.",
    pan: "Invalid PAN format.",
    gst: "Invalid GST format",
    license: "Invalid Driving License format.",
    cin: "Invalid Company CIN format.",
  };

  const patterns = {
    aadhar: /^[1-9][0-9]{11}$/,
    pan: /^[A-Z]{5}[0-9]{4}[A-Z]$/,
    gst: /^33[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}[Z]{1}[0-9A-Z]{1}$/,
    license: /^TN[0-9]{2} ?[0-9]{4}[0-9]{6,7}$/,
    cin: /^[LU]{1}[0-9]{5}[A-Z]{2}[0-9]{4}[A-Z]{3}[0-9]{6}$/,
  };

  const getKYCFile = async () => {
    setLoading(true);
    try {
      const response = await axios.get(
        `${baseURL}odata/DocumentUploads?$filter=refno eq ${localStorage.getItem(
          "id"
        )} and contains(reftype, 'User')`
      );
      // console.log("response", response.data.value);
      if (response.data.value.length !== 0) {
        setInitialDocument([
          {
            name: response.data.value[response.data.value.length - 1].Name,
            status: UploadFileStatus.Initial,
            progress: 0,
            uid: "324-324-324-32-432-4-32-432432-32",
            size: 1024,
            extension: response.data.value[response.data.value.length - 1].Type,
          },
        ]);
        const filePath = `${imageURL}${
          response.data.value[response.data.value.length - 1].ImagePath
        }`;
        const extension: string =
          response.data.value[response.data.value.length - 1].Type;
        fileIcon =
          fileTypeIcons[extension as keyof typeof fileTypeIcons] ||
          fileTypeIcons.default;
        const fileName = `${
          response.data.value[response.data.value.length - 1].Name +
          "." +
          response.data.value[response.data.value.length - 1].Type
        }`;
        setKycFileName(fileName);
        setKycFilePath(filePath);
      }

      getKYCDetails();
    } catch (err) {
      setLoading(false);
      alert(err);
    }
  };

  // Get KYC document API
  const getKYCDetails = async () => {
    try {
      const response = await axios.get(`${baseURL}odata/KycDocuments`);
      console.log("response", localStorage.getItem("kyc_document_id"));
      setkycDetailsData(response.data.value);
      if (localStorage.getItem("kyc_document_id") !== null) {
        for (let k = 0; k < response.data.value.length; k++) {
          if (
            Number(localStorage.getItem("kyc_document_id")) ===
            response.data.value[k].Id
          ) {
            setSelectedKYC(response.data.value[k]);
            setSelectedKYCID(response.data.value[k].Id);
            setSelectedKYCName(response.data.value[k].Name);
            break;
          }
        }
      }
    } catch (err) {
      alert(err);
    }
    setLoading(false);
  };

  // const handleDropdownChange = (e: DropDownListChangeEvent) => {
  //   const { name, value } = e.target;
  //   if (name === "Name") {
  //     setSelectedKYC(e.value);
  //     setSelectedKYCID(e.value.Id);
  //     setSelectedKYCName(e.value.Name);
  //   } else if (name === "country") {
  //     setFormData((prevData) => ({
  //       ...prevData,
  //       ["country"]: e.value.countryName,
  //     }));
  //     setSelectedCountry(e.value);
  //     setSelectedCountryId(e.value.id);
  //     getState(e.value.id);
  //   } else if (name === "state") {
  //     setFormData((prevData) => ({
  //       ...prevData,
  //       ["state"]: e.value.stateName,
  //     }));
  //     setSelectedState(e.value);
  //     setSelectedStateId(e.value.id);
  //     getCity(e.value.id);
  //   } else if (name === "city") {
  //     setFormData((prevData) => ({
  //       ...prevData,
  //       ["city"]: e.value.cityName,
  //     }));
  //     setSelectedCity(e.value);
  //     setSelectedCityId(e.value.id);
  //   }
  // };

  const handleDropdownChange = (e: DropDownListChangeEvent) => {
    const { name, value } = e.target;
    if (name === "Name") {
      setSelectedKYC(e.value);
      setSelectedKYCID(e.value.Id);
      setSelectedKYCName(e.value.Name);

      setFormData((prevData) => ({
        ...prevData,
        kycDocumentNumber: "", // Reset document number when KYC type changes
      }));
    } else if (name === "country") {
      setCountryName("");
      setFormData((prevData) => ({
        ...prevData,
        ["country"]: e.value.countryName,
      }));
      setSelectedCountry(e.value);
      setSelectedCountryId(e.value.id);
      getState(e.value.id);
    } else if (name === "state") {
      setStateName("");
      setFormData((prevData) => ({
        ...prevData,
        ["state"]: e.value.stateName,
      }));
      setSelectedState(e.value);
      setSelectedStateId(e.value.id);
      getCity(e.value.id);
    } else if (name === "city") {
      setCityName("");
      setFormData((prevData) => ({
        ...prevData,
        ["city"]: e.value.cityName,
      }));
      setSelectedCity(e.value);
      setSelectedCityId(e.value.id);
    }
  };

  const CustomSelectMessage = (props: any) => {
    // `props.disabled` indicates if the upload button should be disabled
    return (
      <div
        onClick={!props.disabled ? props.onClick : undefined} // Trigger the file selection
        style={{
          cursor: props.disabled ? "not-allowed" : "pointer",
          border: "0px #fff",
          textAlign: "center",
          color: props.disabled ? "#fff" : "#fff",
          backgroundColor: props.disabled ? "#42B677" : "#42B677",
        }}
      >
        {/* Customize the text shown */}
        <strong>
          {props.disabled ? "Upload disabled" : "Upload New Image"}
        </strong>
      </div>
    );
  };

  const kycValidation = () => {
    const errors: KYCFormErrors = {};
    const isCheckErrors: ErrorKYCRequired = {};

    // KYC File upload validation
    if (!imageData.trim()) {
      errors.customerKYCDocumentErrr = "KYC File is required";
      isCheckErrors.customerKYCDocumentErrr = true;
    }

    // KYC Document upload validation
    if (
      selectedKYC.Name === "Select KYC Document" ||
      selectedKYCName === "Select KYC Document"
    ) {
      errors.customerKYCFileErrr = "KYC Document is required";
    }

    const kycNumber = formData.kycDocumentNumber!.trim();
    if (!kycNumber) {
      errors.customerKycNumberErrr = `${selectedKYCName} is required`;
      isCheckErrors.customerKycNumberErrr = true;
    } else if (!patterns.aadhar.test(kycNumber) && selectedKYCID === 1) {
      errors.customerKycNumberErrr = validationMessages.aadhar;
      isCheckErrors.customerKycNumberErrr = true;
    } else if (!patterns.pan.test(kycNumber) && selectedKYCID === 2) {
      errors.customerKycNumberErrr = validationMessages.pan;
      isCheckErrors.customerKycNumberErrr = true;
    } else if (!patterns.gst.test(kycNumber) && selectedKYCID === 3) {
      errors.customerKycNumberErrr = validationMessages.gst;
      isCheckErrors.customerKycNumberErrr = true;
    } else if (!patterns.license.test(kycNumber) && selectedKYCID === 4) {
      errors.customerKycNumberErrr = validationMessages.license;
      isCheckErrors.customerKycNumberErrr = true;
    } else if (!patterns.cin.test(kycNumber) && selectedKYCID === 5) {
      errors.customerKycNumberErrr = validationMessages.cin;
      isCheckErrors.customerKycNumberErrr = true;
    }

    setChangeKYCError(errors);
    setIsChangeKYCError(isCheckErrors);
    return Object.keys(errors).length === 0;
  };

  const uploadKYCDetailsAPI = async (e: any) => {
    e.preventDefault();
    setShowMessage(true);

    if (kycValidation()) {
      setLoading(true);
      const currentDate = new Date();
      const isoString = currentDate.toISOString();

      let updateKycData = {
        Id: Number(localStorage.getItem("id")),
        Name: localStorage.getItem("name"),
        Password: localStorage.getItem("password"),
        Mobile: localStorage.getItem("mobile"),
        Email: localStorage.getItem("email"),
        customerId: localStorage.getItem("customerId"),
        CompanyId: 1,
        PointOfContact: null,
        KYCDocument: "Yes",
        UserType: true,
        RoleId: 2,
        OTP: 0,
        IsActive: true,
        IsDelete: false,
        CreatedBy: 1,
        CreatedOn: localStorage.getItem("created"),
        ModifiedBy: 1,
        ModifiedOn: isoString,
        Address: localStorage.getItem("address"),
        CompanyName: localStorage.getItem("company_name"),
        KycDocumentId: Number(selectedKYCID),
        GstNumber: localStorage.getItem("gst_number"),
        Notes: "",
        customerType: Number(localStorage.getItem("customer_type")),
        address2: localStorage.getItem("address2"),
        country: localStorage.getItem("country"),
        state: localStorage.getItem("state"),
        city: localStorage.getItem("city"),
        PinCode: localStorage.getItem("pin_code"),
        LastName: localStorage.getItem("last_name"),
        countryId: localStorage.getItem("countryId"),
        stateId: localStorage.getItem("stateId"),
        cityId: localStorage.getItem("cityId"),
        KycDocumentNumber: formData.kycDocumentNumber,
      };
      // console.log(updateKycData);
      try {
        // API call using axios
        const response = await axios.post(
          `${baseURL}odata/UpdateUser?key=${Number(
            localStorage.getItem("id")
          )}`,
          updateKycData,
          {
            headers: header,
          }
        );

        // Check if response exists and has status 200
        if (response && response.status === 200) {
          uploadDocumentFiles(
            localStorage.getItem("id")!,
            currentFile!,
            imageData
          );
        } else {
          setLoading(false);
          console.error("Unexpected response status:", response?.status);
        }
      } catch (err: any) {
        // Handling the error in catch block
        setLoading(false);
        console.error("Internal Server Error:", err.response.data.detail);
      }
    }
  };

  const uploadDocumentFiles = async (
    bookingId: String,
    currentFile: UploadFileInfo,
    base64Result: string
  ) => {
    let uploadData = [
      {
        docUploadGuid: currentFile?.uid,
        docUploadRefType: "User",
        docUploadRefNo: bookingId,
        docUploadRefSubType: "KYC",
        image: [
          {
            value: base64Result,
            fileName: currentFile?.name,
            type: currentFile?.extension?.split(".").pop(),
          },
        ],
      },
    ];
    try {
      const response = await axios.post(
        `${baseURL}odata/JsonUpload`,
        uploadData,
        {
          headers: header,
        }
      );
      if (response.status === 200) {
        setVisible(true);
        setAlertContent("KYC document file has been upload successfully");
        setLoading(false);
        updateKycDetails();
      } else {
        setVisible(true);
        setAlertContent("Server not rechable. Please try again later!");
        setLoading(false);
      }
    } catch (err) {
      setLoading(false);
    }
  };

  const updateKycDetails = () => {
    localStorage.setItem("kyc_document_id", selectedKYCID.toString());
    localStorage.setItem("kycDocumentNumber", formData.kycDocumentNumber!);
    resetKycDetails();
  };

  const resetKycDetails = () => {
    getKYCFile();
    // localStorage.getItem("kyc_document_number",KycDocumentNumber )
    window.location.reload();
    setShowMessage(true);
  };

  const toggleDialog = () => {
    setVisible(!visible);
  };

  const handleDelete = () => {
    setKycFileName("");
    setKycFilePath("");
    setImageData("");
    setInitialDocument([]);
    setShowMessage(false);
  };

  const handleView = () => {
    window.open(kycFilePath, "_blank");
  };

  const [deleteDialog, setDeleteDialog] = useState(false);
  const confirmDeleta = () => {
    setDeleteDialog(false);
    handleDelete();
  };

  const navigateMyOrders = () => {
    navigate("/myorders");
  };

  // Handle file add event
  const handleFileAdd = (event: any) => {
    setShowFileList(true);
    setProfileCheck(event.newState[0].status);
  };

  return (
    <div className="booking-container">
      <div>
        <AppBar className="header-row">
          <AppBarSection>
            <img
              src={require("./assets/images/logo.png")}
              alt="Logo"
              style={{ height: "40px", marginRight: "20px" }}
            />
          </AppBarSection>
          <AppBarSection className="menu-alignment">
            <Label className="top-view top-selected">
              <Link to="/">Book a Space</Link>
            </Label>
          </AppBarSection>
          <AppBarSpacer style={{ flexGrow: 1 }} />
          <AppBarSection className="top-view">
            {profileImage === "" ? (
              <img
                ref={anchorRef}
                src={require("./assets/images/profile.png")}
                style={{
                  height: "40px",
                  width: "40px",
                  borderRadius: "50%",
                  cursor: "pointer",
                }}
                onClick={handleProfileClick}
              />
            ) : (
              <img
                ref={anchorRef}
                src={profileImage!}
                style={{
                  height: "40px",
                  width: "40px",
                  borderRadius: "50%",
                  cursor: "pointer",
                }}
                onClick={handleProfileClick}
              />
            )}
            <Popup
              anchor={anchorRef.current}
              collision={{
                horizontal: "fit",
                vertical: "flip",
              }}
              show={showPopup}
              popupClass={"popup-content"}
              animate={false}
            >
              <div ref={popupRef} style={{ padding: "10px" }}>
                {loggedIn && (
                  <div>
                    <Label
                      style={{ color: "#42B677" }}
                    >{`Welcome ${localStorage.getItem("name")}`}</Label>
                  </div>
                )}
                {loggedIn && (
                  <div>
                    <Button
                      fillMode="link"
                      className="popup-button-block"
                      onClick={navigateNextScreen}
                    >
                      My Bookings
                    </Button>
                    <Button
                      fillMode="link"
                      className="popup-button-block"
                      onClick={navigateMyOrders}
                    >
                      My Orders
                    </Button>
                    <Button
                      fillMode="link"
                      className="popup-button-block popup-button-color"
                    >
                      My Profile
                    </Button>
                  </div>
                )}
                <Button fillMode="link" onClick={handleLoginLogout}>
                  {loggedIn ? "Logout" : "Login"}
                </Button>
              </div>
            </Popup>
          </AppBarSection>
        </AppBar>
        <h2 className="bookings-header">My Profile</h2>
        <div
          style={{
            alignItems: "center",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <AccountCard />
        </div>
      </div>
      {loading && (
        <div className="loading-screen">
          <Loader size="large" type={"infinite-spinner"} />
        </div>
      )}
      {!loading && (
        <div
          style={{
            padding: "20px",
            backgroundColor: "white",
            margin: "20px",
            borderRadius: "20px",
          }}
        >
          {/* Tabs */}
          <div className="booking-header-container">
            {selectedTab === 0 && (
              <button
                style={{ marginTop: "30px" }}
                className="edit-details-button"
                onClick={handleEditOption}
              >
                Edit Details
              </button>
            )}

            <TabStrip
              style={{ marginTop: "40px" }}
              selected={selectedTab}
              onSelect={handleTabSelect}
              className="tabs"
            >
              <TabStripTab title="BASIC DETAILS">
                <div className="booking-list">
                  <form className="form-container">
                    <div className="form-row">
                      <div className="profile-picture-section">
                        <div className="profile-aligh-right">
                          <div className="profile-aligh">
                            <Label className="profile-Label">
                              Profile Picture
                            </Label>
                            <div className="image-container">
                              {profileImage === "" ? (
                                <img
                                  src={require("./assets/A6CC0B0F4DP2012E.png")}
                                  className="profile-picture"
                                />
                              ) : (
                                <img
                                  src={profileImage!}
                                  className="profile-picture"
                                />
                              )}
                            </div>
                          </div>
                          {isEdit === false && (
                            <div className="profile-actions">
                              <Upload
                                className="hide-profile-upload"
                                ref={uploadRefs}
                                restrictions={{
                                  maxFileSize: 2000000,
                                  allowedExtensions: [".jpeg", ".png", ".jpg"],
                                }}
                                batch={false}
                                multiple={false}
                                defaultFiles={[]}
                                withCredentials={false}
                                showFileList={showFileList} // Use state to control visibility
                                disabled={isFileCheck}
                                selectMessageUI={CustomSelectMessage}
                                saveUrl={onProfileSaveRequest}
                                onAdd={handleFileAdd} // Add the onAdd event handler
                              />
                              {showFileList && (
                                <CustomFileList
                                  files={files}
                                  onRemove={onRemove}
                                />
                              )}
                            </div>
                          )}
                        </div>
                        {/* <Label>{profileNote}</Label> */}
                        <div className="kyc-note">
                          {showProfileMessage && (
                            <span>
                              Only JPEG, JPG and PNG files are allowed and
                              Maximum size 2 MB.
                            </span>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="form-fields">
                      {localStorage.getItem("customer_type") === "1" && (
                        <div className="form-group">
                          <Label className="field-label">
                            Company Name
                            <Label className="manditory-fields">*</Label>
                          </Label>
                          {/* <div className="profile-disable"> */}
                          <Input
                            style={{
                              backgroundColor: isEdit ? "#EEEEEE" : "#FFFFFF",
                            }}
                            className="personal-fields"
                            type="text"
                            name="companyName"
                            value={formData.companyName!}
                            onChange={handleInputChange}
                            maxLength={100}
                            placeholder="Enter Company Name"
                            disabled={isEdit}
                          />
                          {/* </div> */}
                          <div>
                            {formData?.companyName!.trim() === "" &&
                              registerRoomBookErrorFormData?.companyNameErr && (
                                <Label className="error-field">
                                  {
                                    registerRoomBookErrorFormData?.companyNameErr
                                  }
                                </Label>
                              )}
                          </div>
                        </div>
                      )}

                      <div className="form-group">
                        <Label>
                          Contact Person Name
                          <Label className="manditory-fields">*</Label>
                        </Label>

                        <div className="form-group-row">
                          <Input
                            style={{
                              backgroundColor: isEdit ? "#EEEEEE" : "#FFFFFF",
                            }}
                            className="personal-fields"
                            type="text"
                            name="firstName"
                            value={formData.firstName!}
                            maxLength={100}
                            onChange={handleInputChange}
                            placeholder="Enter First Name"
                            disabled={isEdit}
                          />
                          <Input
                            style={{
                              backgroundColor: isEdit ? "#EEEEEE" : "#FFFFFF",
                            }}
                            className="personal-fields"
                            type="text"
                            name="lastName"
                            value={formData.lastName!}
                            maxLength={100}
                            onChange={handleInputChange}
                            placeholder="Enter Last Name"
                            disabled={isEdit}
                          />
                        </div>
                        <div>
                          {formData?.firstName!.trim() === "" &&
                            registerRoomBookErrorFormData?.customerNameErr && (
                              <Label className="error-field">
                                {registerRoomBookErrorFormData?.customerNameErr}
                              </Label>
                            )}
                        </div>
                      </div>

                      {/* <div className="form-group">  */}
                      <div className="form-group-row">
                        <div className="profile-mobile-field">
                          <Label>
                            Mobile Number
                            <Label className="manditory-fields">*</Label>
                          </Label>
                          <Input
                            style={{
                              backgroundColor: "#EEEEEE",
                            }}
                            className="field-top personal-fields"
                            name="mobileNumber"
                            value={formData.mobileNumber!}
                            placeholder="Enter Mobile Number"
                            disabled={true}
                          />
                        </div>
                        <div className="profile-email-field">
                          <Label>
                            Email Address
                            <Label className="manditory-fields">*</Label>
                          </Label>
                          <Input
                            style={{
                              backgroundColor: "#EEEEEE",
                            }}
                            className="field-top personal-fields"
                            name="emailAddress"
                            value={formData.emailAddress!}
                            placeholder="Enter Email Address"
                            disabled={true}
                          />
                        </div>
                      </div>
                      {/* </div> */}

                      <div className="form-group-row">
                        <div className="profile-mobile-field">
                          <Label>
                            Address 1
                            <Label className="manditory-fields">*</Label>
                          </Label>
                          {/* <Input
                            style={{
                              backgroundColor: isEdit ? "#EEEEEE" : "#FFFFFF",
                            }}
                            className="personal-fields"
                            type="text"
                            name="addressLine1"
                            value={formData.addressLine1!}
                            maxLength={200}
                            onChange={handleInputChange}
                            placeholder="Enter Address Line 1"
                            disabled={isEdit}
                          /> */}

                          <TextArea
                            style={{
                              backgroundColor: isEdit ? "#EEEEEE" : "#FFFFFF",
                              height: "70px",
                            }}
                            className="personal-fields"
                            name="addressLine1"
                            rows={3}
                            maxLength={100}
                            onChange={handleInputChange}
                            placeholder="Enter Address Line 1"
                            disabled={isEdit}
                            value={formData.addressLine1!}
                          />
                          <div>
                            {formData?.addressLine1! === "" &&
                              registerRoomBookErrorFormData?.customerAddres1Eerrr && (
                                <Label className="error-field">
                                  {
                                    registerRoomBookErrorFormData?.customerAddres1Eerrr
                                  }
                                </Label>
                              )}
                          </div>
                        </div>
                        <div className="profile-email-field">
                          <Label>
                            Address 2
                            <Label className="manditory-fields">*</Label>
                          </Label>
                          {/* <Input
                            style={{
                              backgroundColor: isEdit ? "#EEEEEE" : "#FFFFFF",
                            }}
                            className="personal-fields"
                            type="text"
                            name="addressLine2"
                            value={formData.addressLine2!}
                            maxLength={200}
                            onChange={handleInputChange}
                            placeholder="Enter Address Line 2"
                            disabled={isEdit}
                          /> */}

                          <TextArea
                            style={{
                              backgroundColor: isEdit ? "#EEEEEE" : "#FFFFFF",
                              height: "70px",
                            }}
                            className="personal-fields"
                            name="addressLine2"
                            rows={3}
                            maxLength={100}
                            onChange={handleInputChange}
                            placeholder="Enter Address Line 2"
                            disabled={isEdit}
                            value={formData.addressLine2!}
                          />
                          <div>
                            {formData?.addressLine2! === "" &&
                              registerRoomBookErrorFormData?.customerAddres2Eerrr && (
                                <Label className="error-field">
                                  {
                                    registerRoomBookErrorFormData?.customerAddres2Eerrr
                                  }
                                </Label>
                              )}
                          </div>
                        </div>
                      </div>

                      <div className="form-group-row">
                        <div className="form-group">
                          <Label>
                            Country<Label className="manditory-fields">*</Label>
                          </Label>

                          <DropDownList
                            style={{
                              minWidth: "300px",
                              backgroundColor: "rgb(238, 238, 238)",
                            }}
                            className="personal-fields"
                            textField="countryName"
                            dataItemKey="id"
                            name="country"
                            data={countryData}
                            value={selectedCountry}
                            disabled={isEdit}
                            onChange={handleDropdownChange}
                          />
                          {/* <Input
                            style={{
                              backgroundColor: isEdit ? "#EEEEEE" : "#FFFFFF",
                            }}
                            className="personal-fields"
                            type="text"
                            name="country"
                            value={formData.country!}
                            maxLength={50}
                            onChange={handleInputChange}
                            placeholder="Enter Country"
                            disabled={isEdit}
                          /> */}
                          <div>
                            {selectedCountryId === 0 &&
                              registerRoomBookErrorFormData?.customerCountryErrr && (
                                <Label className="error-field">
                                  {
                                    registerRoomBookErrorFormData?.customerCountryErrr
                                  }
                                </Label>
                              )}
                          </div>
                          {/* <select
                          name="country"
                          value={formData.country}
                          onChange={handleInputChange}
                        >
                          <option value="India">India</option>
                        </select> */}
                        </div>
                        <div className="form-group">
                          <Label>
                            State<Label className="manditory-fields">*</Label>
                          </Label>
                          <DropDownList
                            style={{
                              minWidth: "300px",
                              backgroundColor: "rgb(238, 238, 238)",
                            }}
                            className="personal-fields"
                            textField="stateName"
                            dataItemKey="id"
                            name="state"
                            disabled={
                              !isEdit && selectedCountryId !== 0 ? false : true
                            }
                            data={stateData}
                            value={selectedState}
                            onChange={handleDropdownChange}
                          />

                          {/* <Input
                            style={{
                              backgroundColor: isEdit ? "#EEEEEE" : "#FFFFFF",
                            }}
                            className="personal-fields"
                            type="text"
                            name="state"
                            value={formData.state!}
                            maxLength={50}
                            onChange={handleInputChange}
                            placeholder="Enter State"
                            disabled={isEdit}
                          /> */}
                          <div>
                            {selectedStateId === 0 &&
                              registerRoomBookErrorFormData?.customerStateErrr && (
                                <Label className="error-field">
                                  {
                                    registerRoomBookErrorFormData?.customerStateErrr
                                  }
                                </Label>
                              )}
                          </div>
                          {/* <select
                          name="state"
                          value={formData.state}
                          onChange={handleInputChange}
                        >
                          <option value="">Select State</option>
                        </select> */}
                        </div>
                        <div className="form-group">
                          <Label>
                            City<Label className="manditory-fields">*</Label>
                          </Label>
                          <DropDownList
                            style={{
                              minWidth: "300px",
                              backgroundColor: "rgb(238, 238, 238)",
                            }}
                            className="personal-fields"
                            textField="cityName"
                            dataItemKey="id"
                            name="city"
                            data={cityData}
                            value={selectedCity}
                            disabled={
                              !isEdit && selectedStateId !== 0 ? false : true
                            }
                            onChange={handleDropdownChange}
                          />
                          {/* <Input
                            style={{
                              backgroundColor: isEdit ? "#EEEEEE" : "#FFFFFF",
                            }}
                            className="personal-fields"
                            type="text"
                            name="city"
                            value={formData.city!}
                            maxLength={50}
                            onChange={handleInputChange}
                            placeholder="Enter City"
                            disabled={isEdit}
                          /> */}
                          <div>
                            {selectedCityId === 0 &&
                              registerRoomBookErrorFormData?.customerCityErrr && (
                                <Label className="error-field">
                                  {
                                    registerRoomBookErrorFormData?.customerCityErrr
                                  }
                                </Label>
                              )}
                          </div>
                          {/* <select
                          name="city"
                          value={formData.city}
                          onChange={handleInputChange}
                        >
                          <option value="">Select City</option>
                        </select> */}
                        </div>
                        <div className="form-group">
                          <Label>
                            Pincode<Label className="manditory-fields">*</Label>
                          </Label>
                          <Input
                            style={{
                              backgroundColor: isEdit ? "#EEEEEE" : "#FFFFFF",
                            }}
                            className="personal-fields"
                            name="pincode"
                            value={formData.pincode!}
                            onChange={handleInputChange}
                            placeholder="Enter Pincode"
                            maxLength={6}
                            disabled={isEdit}
                          />
                          <div>
                            {formData?.pincode! === "" &&
                              registerRoomBookErrorFormData?.customerPincodeErrr && (
                                <Label className="error-field">
                                  {
                                    registerRoomBookErrorFormData?.customerPincodeErrr
                                  }
                                </Label>
                              )}
                          </div>
                        </div>
                      </div>

                      <div className="form-group">
                        <Label>GST Number</Label>
                        <Input
                          style={{
                            backgroundColor: isEdit ? "#EEEEEE" : "#FFFFFF",
                          }}
                          className="personal-fields"
                          type="text"
                          name="gstNumber"
                          value={formData.gstNumber!}
                          onChange={handleInputChange}
                          placeholder="Enter GST number"
                          maxLength={15}
                          disabled={isEdit}
                        />
                      </div>
                      {isEdit === false && (
                        <div className="button-group">
                          <button
                            type="submit"
                            className="save-button"
                            disabled={isEdit}
                            onClick={(e) => updateRegisterUserAPI(e)}
                            // onClick={(e) => {
                            //     registerUserAPI(e);
                            //   }}
                          >
                            SAVE
                          </button>
                          <button
                            type="button"
                            className="cancel-button"
                            disabled={isEdit}
                            onClick={handleDisableWithReset}
                          >
                            CANCEL
                          </button>
                        </div>
                      )}
                    </div>
                  </form>
                </div>
              </TabStripTab>

              {/* Change password */}
              <TabStripTab title="CHANGE PASSWORD">
                <div className="booking-list">
                  {/* <div className="password-change-form"> */}
                  {/* <div> */}
                  {/* <div className="password-change-form"> */}
                  <p className="registered-email">
                    Registered email:{" "}
                    <strong>{localStorage.getItem("email")}</strong>
                  </p>

                  {/* <form className="password-form"> */}
                  <div className="form-group">
                    <Label>
                      Current Password
                      <Label className="manditory-fields">*</Label>
                    </Label>
                    <Input
                      className="field-top personal-fields"
                      name="current"
                      type={showCurrentPassword ? "text" : "password"}
                      autoComplete="off"
                      value={currentPassword}
                      placeholder="Enter your current password"
                      // valid={
                      //   decryptBase64Password(
                      //     localStorage.getItem("password")
                      //   )! === currentPassword &&
                      //   isChangePasswordError?.customerPassowordErrr === true
                      // }
                      required={
                        currentPassword === "" &&
                        true &&
                        isChangePasswordError?.customerPassowordErrr === true
                      }
                      onChange={handlePasswordChanges}
                      minLength={8}
                      maxLength={18}
                    />
                    <span
                      onClick={toggleCurrentPasswordVisibility}
                      style={{
                        position: "absolute",
                        right: "40px",
                        marginTop: "10px",
                        top: "140px",
                        cursor: "pointer",
                      }}
                    >
                      {showCurrentPassword ? (
                        <SvgIcon icon={eyeIcon} />
                      ) : (
                        <SvgIcon icon={eyeSlashIcon} />
                      )}
                    </span>
                    {changePasswordError?.customerCurrentPasswordErrr! === "" ||
                      (isChangePasswordError?.customerCurrentPassowordErrr && (
                        <Label className="error-field">
                          {changePasswordError?.customerCurrentPasswordErrr}
                        </Label>
                      ))}
                  </div>

                  <div className="form-group-row-password">
                    <div className="form-group">
                      <Label>
                        New Password
                        <Label className="manditory-fields">*</Label>
                      </Label>
                      <Input
                        className="field-top personal-fields"
                        type={showPassword ? "text" : "password"}
                        placeholder="Enter New Password"
                        name="password"
                        autoComplete="off"
                        value={profilePassword}
                        onChange={handlePasswordChanges}
                        required={
                          profilePassword === "" &&
                          true &&
                          isChangePasswordError?.customerPassowordErrr === true
                        }
                        minLength={8}
                        maxLength={18}
                      />
                      <span
                        onClick={togglePasswordVisibility}
                        style={{
                          position: "relative",
                          marginTop: "-32px",
                          marginLeft: "95%",
                          cursor: "pointer",
                        }}
                      >
                        {showPassword ? (
                          <SvgIcon icon={eyeIcon} />
                        ) : (
                          <SvgIcon icon={eyeSlashIcon} />
                        )}
                      </span>
                      {changePasswordError?.customerPasswordErrr! === "" ||
                        (isChangePasswordError?.customerPassowordErrr && (
                          <Label className="error-field">
                            {changePasswordError?.customerPasswordErrr}
                          </Label>
                        ))}
                    </div>

                    <div className="form-group">
                      <Label>
                        Re-enter New Password
                        <Label className="manditory-fields">*</Label>
                      </Label>
                      <Input
                        className="field-top personal-fields"
                        type={showConfirmPassword ? "text" : "password"}
                        autoComplete="off"
                        value={confirmPassword}
                        onChange={handlePasswordChanges}
                        name="confirmPassword"
                        placeholder="Enter New Password Again"
                        // valid={profilePassword === confirmPassword}
                        required={
                          profilePassword !== confirmPassword ||
                          isChangePasswordError?.customerConfirmPasswordErrr ===
                            true
                        }
                        minLength={8}
                        maxLength={18}
                      />
                      <span
                        onClick={toggleConfirmPasswordVisibility}
                        style={{
                          position: "absolute",
                          right: "40px",
                          marginTop: "45px",
                          // top: "1400px",
                          // transform: "translateY(-50%)",
                          cursor: "pointer",
                        }}
                      >
                        {showConfirmPassword ? (
                          <SvgIcon icon={eyeIcon} />
                        ) : (
                          <SvgIcon icon={eyeSlashIcon} />
                        )}
                      </span>
                      {confirmPassword !== profilePassword &&
                        isChangePasswordError?.customerConfirmPasswordErrr && (
                          <Label className="error-field">
                            {changePasswordError?.customerConfirmPasswordErrr}
                          </Label>
                        )}
                    </div>
                  </div>
                  <div className="button-group">
                    <button
                      type="submit"
                      className="save-button"
                      onClick={changePasswordAPI}
                    >
                      SAVE
                    </button>
                    <button
                      type="button"
                      className="cancel-button"
                      onClick={handlePasswordReset}
                    >
                      RESET
                    </button>
                  </div>
                </div>
              </TabStripTab>

              {/* Upload and Change KYC document */}
              <TabStripTab title="KYC DOCUMENTS">
                <div className="booking-list">
                  <div className="kyc-note">
                    {showMessage && (
                      <span>
                        To upload a new file, delete the existing file.
                      </span>
                    )}
                  </div>
                  <div className="form-group">
                    <Label>
                      Document Type
                      <Label className="manditory-fields">*</Label>
                    </Label>
                    <DropDownList
                      style={{
                        backgroundColor:
                          kycFileName !== "" ? "#EEEEEE" : "#FFFFFF",
                      }}
                      className="personal-fields"
                      textField="Name"
                      dataItemKey="Id"
                      name="Name"
                      data={kycDetailsData}
                      value={selectedKYC}
                      disabled={kycFileName === "" ? false : true}
                      onChange={handleDropdownChange}
                    />
                  </div>
                  <div>
                    {(selectedKYC.Name === "Select KYC Document" ||
                      selectedKYCName === "Select KYC Document") &&
                      changeKYCError?.customerKYCFileErrr && (
                        <Label className="error-field">
                          {changeKYCError?.customerKYCFileErrr}
                        </Label>
                      )}
                  </div>
                  {kycFileName === "" && (
                    <div>
                      <ExternalDropZone
                        className="field-space border-file-upload"
                        uploadRef={uploadRefs}
                        customHint={hint}
                        customNote={note}
                      />
                      <Upload
                        ref={uploadRefs}
                        restrictions={{
                          maxFileSize: 2000000,
                          allowedExtensions: [".jpeg", ".png", ".jpg", ".pdf"],
                        }}
                        batch={false}
                        multiple={false}
                        defaultFiles={initialDocument}
                        withCredentials={false}
                        saveUrl={onSaveRequest}
                        removeUrl={onRemoveRequest}
                      />
                      <div>
                        {imageData === "" &&
                          changeKYCError?.customerKYCDocumentErrr && (
                            <Label className="error-field">
                              {changeKYCError?.customerKYCDocumentErrr}
                            </Label>
                          )}
                      </div>
                    </div>
                  )}
                  <div className="remaining-field">
                    {/* <Label className="field-label">{selectedKYCName}</Label> */}
                    <Label className="field-label">Document Number</Label>
                    <Label className="manditory-fields">*</Label>
                    <Input
                      style={{
                        backgroundColor:
                          kycFileName !== "" ? "#EEEEEE" : "#FFFFFF",
                      }}
                      placeholder={`Enter ${selectedKYCName} Number`}
                      className="field-top personal-fields"
                      name="kycDocumentNumber"
                      autoComplete="off"
                      value={formData.kycDocumentNumber!}
                      disabled={kycFileName === "" ? false : true}
                      maxLength={
                        selectedKYCID === 1
                          ? 12
                          : selectedKYCID === 2
                          ? 10
                          : selectedKYCID === 3
                          ? 15
                          : selectedKYCID === 4
                          ? 15
                          : selectedKYCID === 5
                          ? 21
                          : 0 // Default fallback value if none match
                      }
                      onChange={handleInputChange}
                      required={
                        formData.kycDocumentNumber! === "" &&
                        true &&
                        isChangeKYCError?.customerKycNumberErrr === true
                      }
                    />
                    <div>
                      {!formData.kycDocumentNumber!.trim() &&
                        changeKYCError?.customerKycNumberErrr && (
                          <Label className="error-field">
                            {changeKYCError?.customerKycNumberErrr}
                          </Label>
                        )}
                      {formData.kycDocumentNumber!.trim() !== "" &&
                        changeKYCError?.customerKycNumberErrr && (
                          <Label className="error-field">
                            {changeKYCError?.customerKycNumberErrr}
                          </Label>
                        )}
                    </div>
                  </div>
                  {kycFileName !== "" && (
                    <div className="file-item fileItem">
                      <div className="fileDetails">
                        <span className="fileIcon">
                          <SvgIcon icon={fileIcon} />
                        </span>
                        <span>{kycFileName}</span>
                      </div>
                      <div className="actions">
                        <Button
                          fillMode="flat"
                          onClick={handleView}
                          className="viewButton"
                        >
                          View Document
                        </Button>
                        <Button
                          fillMode="flat"
                          onClick={(e) => setDeleteDialog(true)}
                          className="deleteButton"
                        >
                          Edit
                        </Button>
                      </div>
                    </div>
                  )}

                  {kycFileName === "" && (
                    <div className="form-actions-KYC">
                      <button
                        type="submit"
                        className="save-button-KYC"
                        onClick={uploadKYCDetailsAPI}
                      >
                        SAVE
                      </button>
                      <button
                        type="button"
                        className="cancel-button-KYC"
                        onClick={resetKycDetails}
                      >
                        CANCEL
                      </button>
                    </div>
                  )}
                </div>
              </TabStripTab>
            </TabStrip>
          </div>
        </div>
      )}
      {deleteDialog && (
        <Dialog
          onClose={() => setDeleteDialog(false)}
          className="dialog-container"
        >
          <div className="dialog-content">
            <Label className="dialog-heading">Alert Dialog</Label>
            <Label>Are you sure want to edit the KYC document</Label>
            <div className="login-button-content">
              <Button
                className="login-button-dialog"
                onClick={(e) => {
                  confirmDeleta();
                }}
              >
                Yes
              </Button>
              <Button
                className="login-button-dialog"
                onClick={(e) => setDeleteDialog(false)}
              >
                No
              </Button>
            </div>
          </div>
        </Dialog>
      )}
      {logoutDialog && (
        <Dialog
          onClose={() => setLogoutDialog(false)}
          className="dialog-container"
        >
          <div className="dialog-content">
            <Label className="dialog-heading">Alert Dialog</Label>
            <Label>{alertContent}</Label>
            <div className="login-button-content">
              <Button
                className="login-button-dialog"
                onClick={(e) => logoutHandler(true)}
              >
                Yes
              </Button>
              <Button
                className="login-button-dialog"
                onClick={(e) => logoutHandler(false)}
              >
                No
              </Button>
            </div>
          </div>
        </Dialog>
      )}
      {changeDialog && (
        <Dialog
          onClose={() => setChangeDialog(false)}
          className="dialog-container"
        >
          <div className="dialog-content">
            <Label className="dialog-heading">Alert Dialog</Label>
            <Label>{alertContent}</Label>
            <div
              style={{
                marginTop: "20px",
                width: "120px",
              }}
            >
              <Button
                className="alert-button"
                onClick={(e) => removeUserDetails()}
              >
                Yes
              </Button>
            </div>
          </div>
        </Dialog>
      )}
      {visible && (
        <Dialog
          title={"Message"}
          onClose={toggleDialog}
          style={{ alignItems: "center" }}
        >
          <Label
            style={{
              height: "80px",
              textAlign: "center",
              display: "flex",
              flexDirection: "column",
            }}
          >
            {alertContent}
            <div
              style={{
                padding: "20px",
              }}
            />
            <Button
              className="alert-button"
              type="button"
              onClick={toggleDialog}
            >
              Ok
            </Button>
          </Label>
        </Dialog>
      )}
    </div>
  );
};
